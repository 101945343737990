
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppOrganizationMemberIndex} from "@/models/app/organization-member";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElForm,
  ElFormItem,
  ElButton,
} from "element-plus";
import {Delete} from "@element-plus/icons-vue";

// Services
import {deleteRequest} from "@/services/api/request";
import {getTranslation} from "@/services/app/translation";

@Options({
  props: [
    "organizationMember",
  ],
  components: {
    ElDialog,
    ElForm,
    ElFormItem,
    ElButton,
    Delete,
  },
})
export default class AppAdministratorOrganizationsDetailsOrganizationMembersListFormRemoveIndexVue extends Vue {
  isLoading = false;
  isDialogVisible = false;

  organizationMember: AppOrganizationMemberIndex = new AppOrganizationMemberIndex();

  formData: AppOrganizationMemberIndex = new AppOrganizationMemberIndex();

  get translation(): any {
    return getTranslation([
      "areYouSure",
      "remove",
      "yesIAm",
    ]);
  }

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await deleteRequest({
      uri: "/administration/organization-member/remove",
      formData: this.formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        this.$emit('getOrganizationMembers');
        this.isDialogVisible = false;
        this.resetFormData();
      }
    });
    this.isLoading = false;
  }

  setFormData(): void {
    this.formData = this.organizationMember;
  }

  resetFormData(): void {
    this.formData = new AppOrganizationMemberIndex();
  }

  updated(): void {
    this.setFormData();
  }
}
