
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Models
import {AppOrganizationMemberIndex} from "@/models/app/organization-member";
import {ResponseIndex} from "@/models/response";

// Components
import {User} from "@element-plus/icons-vue";
import AppAdministratorOrganizationsDetailsOrganizationMembersListFormFilterIndexVue from "@/views/app/administrator/organizations/details/organization-members/list/form/filter.vue";
import AppAdministratorOrganizationsDetailsOrganizationMembersListFormCreateIndexVue from "@/views/app/administrator/organizations/details/organization-members/list/form/create.vue";
import AppAdministratorOrganizationsDetailsOrganizationMembersListFormUpdateIndexVue from "@/views/app/administrator/organizations/details/organization-members/list/form/update.vue";
import AppAdministratorOrganizationsDetailsOrganizationMembersListFormRemoveIndexVue from "@/views/app/administrator/organizations/details/organization-members/list/form/remove.vue";
import LoadingIndexVue from "@/components/loading.vue";

// Services
import {getRequest} from "@/services/api/request";
import {getTranslation} from "@/services/app/translation";

@Options({
  components: {
    User,
    AppAdministratorOrganizationsDetailsOrganizationMembersListFormFilterIndexVue,
    AppAdministratorOrganizationsDetailsOrganizationMembersListFormCreateIndexVue,
    AppAdministratorOrganizationsDetailsOrganizationMembersListFormUpdateIndexVue,
    AppAdministratorOrganizationsDetailsOrganizationMembersListFormRemoveIndexVue,
    LoadingIndexVue,
  },
})
export default class AppAdministratorOrganizationsDetailsOrganizationMembersListIndexVue extends Vue {
  isLoading = false;

  organizationMembers: Array<AppOrganizationMemberIndex> = [];

  get isDataLoaded(): boolean {
    return this.organizationMembers.length > 0 && this.isLoading === false;
  }

  get isDataLoadedAndEmpty(): boolean {
    return this.organizationMembers.length === 0 && this.isLoading === false;
  }

  get translation(): any {
    return getTranslation([
      "administrator",
      "noData",
      "organizationMembers",
      "undefined",
    ]);
  }

  isAdministrator(userId: string | string[]): boolean {
    return userId === store.getters.getAuthentication.id;
  }

  getOrganizationMemberRole(role: string): string {
    if (role === "administrator") {
      return this.translation.administrator;
    }

    return this.translation.undefined;
  }

  async getOrganizationMembers(searchQuery = "*"): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/administration/organization-members`,
      formData: {
        organization_id: this.$route.params.id,
        searchQuery: searchQuery,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.organizationMembers = r.data.sort(function (a, b) {
          if (a.user.content.name < b.user.content.name) {
            return -1;
          }
          if (a.user.content.name > b.user.content.name) {
            return 1;
          }
          return 0;
        });
        this.organizationMembers = this.organizationMembers.sort(function (a, b) {
          if (a.role < b.role) {
            return -1;
          }
          if (a.role > b.role) {
            return 1;
          }
          return 0;
        });
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    await this.getOrganizationMembers();
  }
}
