
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppOrganizationMemberIndex} from "@/models/app/organization-member";
import {AppOrganizationMemberCreateIndex} from "@/models/app/organization-member/create";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElForm,
  ElFormItem,
  ElInput,
  ElSelect,
  ElOption,
  ElButton,
  ElAlert,
} from "element-plus";
import {Plus} from "@element-plus/icons-vue";

// Services
import {getRequest, postRequest} from "@/services/api/request";
import {getTranslation} from "@/services/app/translation";

@Options({
  props: [
    "organizationMembers",
  ],
  components: {
    ElDialog,
    ElForm,
    ElFormItem,
    ElInput,
    ElSelect,
    ElOption,
    ElButton,
    ElAlert,
    Plus,
  },
})
export default class AppAdministratorOrganizationsDetailsOrganizationMembersListFormCreateIndexVue extends Vue {
  isLoading = false;
  isDialogVisible = false;
  isUserFound = false;
  isUserForbidden = false;

  organizationMembers: Array<AppOrganizationMemberIndex> = [];

  formData: AppOrganizationMemberCreateIndex = new AppOrganizationMemberCreateIndex();
  formDataRules = {
    email: [
      {
        required: true,
        type: "email",
        trigger: "blur",
      },
    ],
    password: [
      {
        required: true,
        trigger: "blur",
      },
    ],
    content: {
      name: [
        {
          required: true,
          trigger: "blur",
        },
      ],
      surname: [
        {
          required: true,
          trigger: "blur",
        },
      ],
    },
  };

  get translation(): any {
    return getTranslation([
      "add",
      "administrator",
      "choose",
      "email",
      "emailPlaceholder",
      "name",
      "namePlaceholder",
      "no",
      "password",
      "passwordPlaceholder",
      "role",
      "surname",
      "surnamePlaceholder",
      "userIsAlreadyInOrganization",
      "verification",
      "yes",
    ]);
  }

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    if (this.isUserFound) {
      this.submitForm();
    } else {
      this.$refs.formComponent.validate((response: any) => {
        if (response) {
          this.submitForm();
        }
      });
    }
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await postRequest({
      uri: "/administration/organization-member/create",
      formData: this.formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        this.$emit('getOrganizationMembers');
        this.isDialogVisible = false;
        this.resetFormData();
      }
    });
    this.isLoading = false;
  }

  async searchOrganizationMembers(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/administration/organization-member/search`,
      formData: {
        email: this.formData.email
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        const users = this.organizationMembers.filter((item: any) => {
          return item.user.id === r.data.id
        });

        if (users.length) {
          this.isUserFound = false;
          this.isUserForbidden = true;
        } else {
          this.isUserFound = false;
          this.isUserForbidden = false;
        }
      }

      if (r.status === "warning") {
        this.isUserFound = false;
        this.isUserForbidden = false;
      }
    });
    this.isLoading = false;
  }

  created(): void {
    this.formData.organization_id = this.$route.params.id;
  }

  resetFormData(): void {
    this.formData = new AppOrganizationMemberCreateIndex(this.$route.params.id);
  }
}
