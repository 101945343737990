import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9771c4e0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-administrator-organizations-details-organization-members-list-form-update" }
const _hoisted_2 = { class: "app-administrator-organizations-details-organization-members-list-form-update__action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Edit = _resolveComponent("Edit")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        href: "#",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.isDialogVisible = true), ["prevent"]))
      }, [
        _createVNode(_component_Edit)
      ])
    ]),
    _createVNode(_component_el_dialog, {
      title: _ctx.translation.edit,
      modelValue: _ctx.isDialogVisible,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isDialogVisible) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          class: "el-form--default",
          model: _ctx.formData,
          rules: _ctx.formDataRules,
          ref: "formComponent",
          onSubmit: _withModifiers(_ctx.validateForm, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: _ctx.translation.role,
              prop: "role"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.formData.role,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.role) = $event)),
                  placeholder: _ctx.translation.choose,
                  filterable: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_option, {
                      label: _ctx.translation.administrator,
                      value: "administrator"
                    }, null, 8, ["label"])
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.translation.name,
              prop: "user.content.name"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.formData.user.content.name,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.user.content.name) = $event)),
                  placeholder: _ctx.translation.namePlaceholder
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.translation.surname,
              prop: "user.content.surname"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.formData.user.content.surname,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.user.content.surname) = $event)),
                  placeholder: _ctx.translation.surnamePlaceholder
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.translation.verification,
              prop: "user.is_verified"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.formData.user.is_verified,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.user.is_verified) = $event)),
                  placeholder: _ctx.translation.choose,
                  filterable: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_option, {
                      label: _ctx.translation.yes,
                      value: 1
                    }, null, 8, ["label"]),
                    _createVNode(_component_el_option, {
                      label: _ctx.translation.no,
                      value: 0
                    }, null, 8, ["label"])
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  type: "primary",
                  class: "el-button--primary el-button--block",
                  "native-type": "submit",
                  loading: _ctx.isLoading
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translation.save), 1)
                  ]),
                  _: 1
                }, 8, ["loading"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules", "onSubmit"])
      ]),
      _: 1
    }, 8, ["title", "modelValue"])
  ]))
}