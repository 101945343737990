
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AdministratorNavigationVue from "@/components/administrator/navigation.vue";
import AdministratorNavigationOrganizationDetailsVue from "@/components/administrator/navigation-organization-details.vue";
import AppAdministratorOrganizationsDetailsOrganizationMembersListIndexVue from "@/views/app/administrator/organizations/details/organization-members/list/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    AdministratorNavigationVue,
    AdministratorNavigationOrganizationDetailsVue,
    AppAdministratorOrganizationsDetailsOrganizationMembersListIndexVue,
  },
})
export default class AppAdministratorOrganizationsDetailsOrganizationMembersIndexVue extends Vue {}
