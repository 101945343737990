import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ceb3ffcc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-administrator-organizations-details-organization-members-list-form-create" }
const _hoisted_2 = { class: "app-administrator-organizations-details-organization-members-list-form-create__action" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Plus = _resolveComponent("Plus")!
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        href: "#",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.isDialogVisible = true), ["prevent"]))
      }, [
        _createVNode(_component_Plus)
      ])
    ]),
    _createVNode(_component_el_dialog, {
      title: _ctx.translation.add,
      modelValue: _ctx.isDialogVisible,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.isDialogVisible) = $event))
    }, {
      default: _withCtx(() => [
        (_ctx.isUserForbidden)
          ? (_openBlock(), _createBlock(_component_el_alert, {
              key: 0,
              title: _ctx.translation.userIsAlreadyInOrganization,
              type: "error",
              closable: false
            }, null, 8, ["title"]))
          : _createCommentVNode("", true),
        _createVNode(_component_el_form, {
          class: "el-form--default",
          model: _ctx.formData,
          rules: _ctx.formDataRules,
          ref: "formComponent",
          onSubmit: _withModifiers(_ctx.validateForm, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: _ctx.translation.email,
              prop: "email"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  type: "email",
                  modelValue: _ctx.formData.email,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.email) = $event)),
                  placeholder: _ctx.translation.emailPlaceholder,
                  onChange: _ctx.searchOrganizationMembers
                }, null, 8, ["modelValue", "placeholder", "onChange"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.translation.password,
              prop: "password",
              class: _normalizeClass({ 'd-none': _ctx.isUserFound || _ctx.isUserForbidden })
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  type: "password",
                  modelValue: _ctx.formData.password,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.password) = $event)),
                  placeholder: _ctx.translation.passwordPlaceholder,
                  "show-password": ""
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label", "class"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.translation.role,
              prop: "role",
              class: _normalizeClass({ 'd-none': _ctx.isUserForbidden })
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.formData.role,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.role) = $event)),
                  placeholder: _ctx.translation.choose,
                  filterable: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_option, {
                      label: _ctx.translation.administrator,
                      value: "administrator"
                    }, null, 8, ["label"])
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label", "class"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.translation.name,
              prop: "content.name",
              class: _normalizeClass({ 'd-none': _ctx.isUserFound || _ctx.isUserForbidden })
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.formData.content.name,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.content.name) = $event)),
                  placeholder: _ctx.translation.namePlaceholder
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label", "class"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.translation.surname,
              prop: "content.surname",
              class: _normalizeClass({ 'd-none': _ctx.isUserFound || _ctx.isUserForbidden })
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.formData.content.surname,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.content.surname) = $event)),
                  placeholder: _ctx.translation.surnamePlaceholder
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label", "class"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.translation.verification,
              prop: "is_verified",
              class: _normalizeClass({ 'd-none': _ctx.isUserFound || _ctx.isUserForbidden })
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.formData.is_verified,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.is_verified) = $event)),
                  placeholder: _ctx.translation.choose,
                  filterable: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_option, {
                      label: _ctx.translation.yes,
                      value: true
                    }, null, 8, ["label"]),
                    _createVNode(_component_el_option, {
                      label: _ctx.translation.no,
                      value: false
                    }, null, 8, ["label"])
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }, 8, ["label", "class"]),
            _createVNode(_component_el_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  type: "primary",
                  class: "el-button--primary el-button--block",
                  "native-type": "submit",
                  disabled: _ctx.isUserForbidden,
                  loading: _ctx.isLoading
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translation.add), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "loading"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules", "onSubmit"])
      ]),
      _: 1
    }, 8, ["title", "modelValue"])
  ]))
}