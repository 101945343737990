
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppOrganizationMemberIndex} from "@/models/app/organization-member";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElForm,
  ElFormItem,
  ElInput,
  ElSelect,
  ElOption,
  ElButton,
  ElAlert,
} from "element-plus";
import {Edit} from "@element-plus/icons-vue";

// Services
import {putRequest} from "@/services/api/request";
import {getTranslation} from "@/services/app/translation";

@Options({
  props: [
    "organizationMember",
  ],
  components: {
    ElDialog,
    ElForm,
    ElFormItem,
    ElInput,
    ElSelect,
    ElOption,
    ElButton,
    ElAlert,
    Edit,
  },
})
export default class AppAdministratorOrganizationsDetailsOrganizationMembersListFormUpdateIndexVue extends Vue {
  isLoading = false;
  isDialogVisible = false;

  organizationMember: AppOrganizationMemberIndex = new AppOrganizationMemberIndex();

  formData: AppOrganizationMemberIndex = new AppOrganizationMemberIndex();
  formDataRules = {
    user: {
      content: {
        name: [
          {
            required: true,
            trigger: "blur",
          },
        ],
        surname: [
          {
            required: true,
            trigger: "blur",
          },
        ],
      },
    },
  };

  get translation(): any {
    return getTranslation([
      "administrator",
      "choose",
      "edit",
      "name",
      "namePlaceholder",
      "no",
      "role",
      "save",
      "surname",
      "surnamePlaceholder",
      "verification",
      "yes",
    ]);
  }

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await putRequest({
      uri: "/administration/organization-member/update",
      formData: this.formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        this.$emit('getOrganizationMembers');
        this.isDialogVisible = false;
        this.resetFormData();
      }
    });
    this.isLoading = false;
  }

  setFormData(): void {
    this.formData = this.organizationMember;
  }

  resetFormData(): void {
    this.formData = new AppOrganizationMemberIndex();
  }

  updated(): void {
    this.setFormData();
  }
}
