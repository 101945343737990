import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f480a54e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-administrator-organizations-details-organization-members-list" }
const _hoisted_2 = { class: "box" }
const _hoisted_3 = { class: "box__headline" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "action" }
const _hoisted_6 = { class: "box__filter" }
const _hoisted_7 = {
  key: 0,
  class: "box__list"
}
const _hoisted_8 = { class: "item__avatar" }
const _hoisted_9 = { class: "item__content" }
const _hoisted_10 = { class: "item__content-name" }
const _hoisted_11 = { class: "item__content-role" }
const _hoisted_12 = {
  key: 0,
  class: "item__action"
}
const _hoisted_13 = {
  key: 1,
  class: "box__message"
}
const _hoisted_14 = {
  key: 2,
  class: "box__loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppAdministratorOrganizationsDetailsOrganizationMembersListFormCreateIndexVue = _resolveComponent("AppAdministratorOrganizationsDetailsOrganizationMembersListFormCreateIndexVue")!
  const _component_AppAdministratorOrganizationsDetailsOrganizationMembersListFormFilterIndexVue = _resolveComponent("AppAdministratorOrganizationsDetailsOrganizationMembersListFormFilterIndexVue")!
  const _component_User = _resolveComponent("User")!
  const _component_AppAdministratorOrganizationsDetailsOrganizationMembersListFormUpdateIndexVue = _resolveComponent("AppAdministratorOrganizationsDetailsOrganizationMembersListFormUpdateIndexVue")!
  const _component_AppAdministratorOrganizationsDetailsOrganizationMembersListFormRemoveIndexVue = _resolveComponent("AppAdministratorOrganizationsDetailsOrganizationMembersListFormRemoveIndexVue")!
  const _component_LoadingIndexVue = _resolveComponent("LoadingIndexVue")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.translation.organizationMembers), 1),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_AppAdministratorOrganizationsDetailsOrganizationMembersListFormCreateIndexVue, {
            "organization-members": _ctx.organizationMembers,
            onGetOrganizationMembers: _ctx.getOrganizationMembers
          }, null, 8, ["organization-members", "onGetOrganizationMembers"])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_AppAdministratorOrganizationsDetailsOrganizationMembersListFormFilterIndexVue, { onGetOrganizationMembers: _ctx.getOrganizationMembers }, null, 8, ["onGetOrganizationMembers"])
      ]),
      (_ctx.isDataLoaded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.organizationMembers, (organizationMember) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "item",
                key: organizationMember.id
              }, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_User)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, _toDisplayString(organizationMember.user.content.name) + " " + _toDisplayString(organizationMember.user.content.surname), 1),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.getOrganizationMemberRole(organizationMember.role)), 1)
                  ])
                ]),
                (!_ctx.isAdministrator(organizationMember.user_id))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createVNode(_component_AppAdministratorOrganizationsDetailsOrganizationMembersListFormUpdateIndexVue, {
                        "organization-member": organizationMember,
                        onGetOrganizationMembers: _ctx.getOrganizationMembers
                      }, null, 8, ["organization-member", "onGetOrganizationMembers"]),
                      _createVNode(_component_AppAdministratorOrganizationsDetailsOrganizationMembersListFormRemoveIndexVue, {
                        "organization-member": organizationMember,
                        onGetOrganizationMembers: _ctx.getOrganizationMembers
                      }, null, 8, ["organization-member", "onGetOrganizationMembers"])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_ctx.isDataLoadedAndEmpty)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.translation.noData), 1))
        : _createCommentVNode("", true),
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            _createVNode(_component_LoadingIndexVue)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}